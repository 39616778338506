import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { OfficeIconComponent } from '../../GlobalNavigationIcons';
import { i18n } from '../../globalNavigationTranslations';

import { AbstractCompanyHubItem } from './AbstractCompanyHubItem';

export const CompanyHubItem = () => (
	<AbstractCompanyHubItem navVersion="4">
		{({ companyHubName, isSelected, namedRoute, onClick }) => (
			<MenuLinkItem
				href={namedRoute?.toUrl() || '#'}
				elemBefore={OfficeIconComponent}
				onClick={onClick}
				isSelected={isSelected}
			>
				{companyHubName || <FormattedMessage {...i18n.companyHub} />}
			</MenuLinkItem>
		)}
	</AbstractCompanyHubItem>
);
